@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Poppins, system-ui, sans-serif;
  }
}

.react-datepicker__day--selected {
  @apply bg-transparent text-black;
}

.react-datepicker__day--selected:hover {
  @apply bg-transparent;
}

.react-datepicker__day--highlighted:hover {
  @apply bg-[#32be3f];
}

.react-datepicker__day--highlighted {
  @apply bg-[#32be3f];
}
